<template>
  <div class="w-full">
    <BoxWithTitle title="Conditions générales d’utilisation">
      <template #body>
        <p v-html="cgu.data.context"></p>
      </template>
    </BoxWithTitle>
    <RevelationMobileButton />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BoxWithTitle from '../Components/BoxWithTitle.vue'
export default {
  components: {
    BoxWithTitle,
  },
  computed: {
    ...mapGetters({
      cgu: 'auth/cgu',
    }),
  },
}
</script>
